@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
	margin: 0;
	font-family: 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	padding: 0;
	box-sizing: content-box;
	overflow-x: auto;
}

ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

a {
    color: inherit;
}

button {
    text-transform: capitalize!important;
}

.rc-tree-node-selected {
    background-color: #248551!important;
    border: 1px #248551 solid!important;
    display: inline-block!important;
    padding-inline: 10px!important;
    border-radius: 10px;
    color: #fff;
    font-weight: bold;
}

.MuiDrawer-paper { margin-top: 64px; }

.MuiAutocomplete-option {
    overflow: initial!important;
    min-width: fit-content;
    font-size: 12px;
    padding: 2px!important;
};

.MuiAutocomplete-option .MuiCheckbox-root {
    padding: 0!important;
}

.upload {
    position: relative;
    animation: upload 3s infinite;
}

@keyframes upload {
    0% { bottom: 0; }

    50% { bottom: 3px; }

    100% { bottom: 5px; }
}
